<template>
  <div>
    <div class="d-flex flex-column p-1 questionbox">
      <div class="question-label">
        <p class="px-1 m-0">
          <b-button
            class="my-1"
            variant="outline-secondary"
            size="sm"
            v-if="isShowCorrection"
            style="background: yellow; font-size: 1.5em; padding: 2px;"
            @click="
              onClickCorrectionAlert(
                question?.worksession_survey_response_approval
              )
            "
          >
            <b-icon icon="info"></b-icon>
          </b-button>
          {{ question.hideLabel ? "&nbsp;" : question.name }}
          <span v-if="isMandatoryThisQuestion" class="required">*</span>
          <b-button
            class="mt-1"
            variant="outline-secondary"
            size="sm"
            v-if="question?.surveyQuestionPhoto"
            @click="onClickCompQueImage(question?.surveyQuestionPhoto)"
          >
            <b-icon icon="card-image"></b-icon>
          </b-button>
        </p>
        <p class="px-1 m-0" v-if="isPSNotZeroLine">
          {{
            formatStrPSPointsLine() == "" ? "&nbsp;" : formatStrPSPointsLine()
          }}
        </p>
      </div>
      <div
        :class="
          `d-flex justify-content-center question-content ${calcBackgroundColor}`
        "
      >
        <TextAreaInput
          @input="emit"
          v-if="typeStr === 'string'"
          v-model="result"
          :readOnlyInputs="readOnlyInputs"
          :importedDataStyling="importedDataStyling"
        ></TextAreaInput>

        <TextAreaInput
          @input="emit"
          v-else-if="typeStr === 'longtext'"
          v-model="result"
          :readOnlyInputs="readOnlyInputs"
          :importedDataStyling="importedDataStyling"
        ></TextAreaInput>

        <ActionBoxInput
          @input="emit"
          v-else-if="typeStr === 'action'"
          v-model="result"
          :options="question.options"
          :optionScores="optionScores"
          :readOnlyInputs="readOnlyInputs"
          :importedDataStyling="importedDataStyling"
        ></ActionBoxInput>

        <SelectBoxInput
          @input="emit"
          v-else-if="typeStr === 'select'"
          v-model="result"
          :options="question.options"
          :optionScores="optionScores"
          :readOnlyInputs="readOnlyInputs"
          :importedDataStyling="importedDataStyling"
        ></SelectBoxInput>

        <BooleanInput
          @input="emit"
          v-else-if="typeStr === 'bool'"
          v-model="result"
          :options="question.options"
          :optionScores="optionScores"
          :readOnlyInputs="readOnlyInputs"
          :importedDataStyling="importedDataStyling"
        ></BooleanInput>

        <CheckboxInput
          @input="emit"
          v-else-if="typeStr === 'checkbox'"
          v-model="result"
          :options="question.options"
          :optionScores="optionScores"
          :readOnlyInputs="readOnlyInputs"
          :importedDataStyling="importedDataStyling"
        ></CheckboxInput>

        <PriceInput
          @input="emit"
          v-else-if="typeStr === 'money'"
          v-model="result"
          :readOnlyInputs="readOnlyInputs"
          :importedDataStyling="importedDataStyling"
          :isKPIthisQuestion="isKPIthisQuestion"
          :foundObjectiveValue="foundObjectiveValue"
        ></PriceInput>

        <DecimalInput
          @input="emit"
          v-else-if="typeStr === 'decimal'"
          v-model="result"
          :optionScores="optionScores"
          :readOnlyInputs="readOnlyInputs"
          :importedDataStyling="importedDataStyling"
          :isKPIthisQuestion="isKPIthisQuestion"
          :foundObjectiveValue="foundObjectiveValue"
        ></DecimalInput>

        <NumberInput
          @input="emit"
          v-else-if="typeStr === 'number'"
          v-model="result"
          :optionScores="optionScores"
          :readOnlyInputs="readOnlyInputs"
          :importedDataStyling="importedDataStyling"
          :isKPIthisQuestion="isKPIthisQuestion"
          :foundObjectiveValue="foundObjectiveValue"
        ></NumberInput>

        <DateInput
          @input="emit"
          v-else-if="typeStr === 'date'"
          v-model="result"
          :readOnlyInputs="readOnlyInputs"
          :importedDataStyling="importedDataStyling"
        ></DateInput>

        <PhotoInput
          @input="emit"
          v-else-if="typeStr === 'photo'"
          v-model="result"
          :worksessionPosBrandId="worksessionPosBrandId"
          :readOnlyInputs="readOnlyInputs"
          :photoType="question.photoType"
          :attachments="question.attachments"
          :surveyListItem="surveyListItem"
          :component="component"
          :proOrMat="proOrMat"
          :question="question"
          :assignTypes="assignTypes"
          @deleteAttachmentItem="delId => deleteAttachment(delId)"
          @onClickSaveComments="saveData => onClickSaveComments(saveData)"
          @updatePhotosPortalAttributes="
            update_data => updatePhotosPortalAttributes(update_data)
          "
        ></PhotoInput>

        <b-alert v-else class="p-2 m-0" variant="danger" show>
          Not implemented !
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../services/apiService";
import { QUESTION_TYPES } from "@/views/surveys/models";
import { mapGetters } from "vuex";
import TextInput from "./inputs/Text";
import TextAreaInput from "./inputs/TextArea";
import SelectBoxInput from "./inputs/SelectBox";
import ActionBoxInput from "./inputs/ActionBox";
import BooleanInput from "./inputs/Boolean";
import CheckboxInput from "./inputs/Checkbox";
import DecimalInput from "./inputs/Decimal";
import NumberInput from "./inputs/Number";
import PriceInput from "./inputs/Price";
import DateInput from "./inputs/Date";
import PhotoInput from "./inputs/Photo";

export default {
  name: "surveyQuestion",
  props: [
    "currentVisitType",
    "getImportedAndKPIObjectiveValue",
    "worksessionPosBrandId",
    "isTableView",
    "surveyListItem",
    "component",
    "proOrMat",
    "question",
    "initialValue",
    "currentScore",
    "maximumScore",
    "optionScores",
    "detectedSurveyChainIdForPuncDetails",
    "worksession_survey_response_approval",
    "isPSNotZeroLine",
    "assignTypes"
  ],
  components: {
    TextInput,
    TextAreaInput,
    SelectBoxInput,
    ActionBoxInput,
    BooleanInput,
    CheckboxInput,
    PriceInput,
    NumberInput,
    DecimalInput,
    DateInput,
    PhotoInput
  },
  watch: {
    initialValue(newVal, oldVal) {
      this.result = newVal;
    }
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("myroute", [
      "currentPosition",
      // "currentGmapAddress",
      "isEndedSession"
    ]),
    isMandatoryThisQuestion() {
      let ret = this.question?.isMandatory;
      if ([QUESTION_TYPES.PHOTO].includes(this.question.questionType)) {
        const {
          isRequiredPortalComment,
          isRequiredPortalScore
        } = this.question;
        if (!ret) {
          if (isRequiredPortalComment || isRequiredPortalScore) {
            ret = true;
          }
        }
      }
      return ret;
    },
    importedDataStyling() {
      return this.isImportedData || this.isCalculationData
        ? "isImportedData"
        : "";
    },
    isShowCorrection() {
      let ret = false;
      if (
        this.question?.worksession_survey_response_approval &&
        this.question?.worksession_survey_response_approval.approvalStatus ===
          "NO"
      ) {
        ret = true;
      }
      return ret;
    },
    isImportedData() {
      let ret = false;
      if (["IMPORT_DATA"].includes(this.question.questionImportType))
        ret = true;
      return ret;
    },
    isCalculationData() {
      let ret = false;
      if (["CALCULATION_DATA"].includes(this.question.questionImportType))
        ret = true;
      return ret;
    },
    readOnlyInputs() {
      let isReadOnly = false;
      if (this.isEndedSession) return true;
      if (this.question?.isAlwaysEditing) {
        return false;
      }
      if (
        ["IMPORT_DATA", "CALCULATION_DATA"].includes(
          this.question.questionImportType
        )
      )
        isReadOnly = true;
      if (["PHONE"].includes(this.currentVisitType)) isReadOnly = true;
      return isReadOnly;
    },
    strValue() {
      try {
        return this.result;
      } catch (e) {
        return this.result;
      }
    },
    isCurrentScoreBigThanMaximum() {
      if (
        !isNaN(parseFloat(this.currentScore)) &&
        !isNaN(parseFloat(this.maximumScore)) &&
        this.maximumScore > 0
      ) {
        if (this.currentScore >= this.maximumScore) {
          return true;
        }
      }
      return false;
    },
    thisQuestionType() {
      let ret = null;
      if (
        this.question?.survey_question_type &&
        this.question?.survey_question_type?.type
      ) {
        ret = this.question?.survey_question_type.type;
      }
      return ret;
    },
    isKPIthisQuestion() {
      let ret = false;
      if (
        [
          QUESTION_TYPES.MONEY,
          QUESTION_TYPES.NUMBER,
          QUESTION_TYPES.DECIMAL
        ].includes(this.thisQuestionType) &&
        this.question?.isKPI
      ) {
        return true;
      }
      return ret;
    },
    isAvailableImportedObjective() {
      let ret = false;
      if (
        [
          QUESTION_TYPES.MONEY,
          QUESTION_TYPES.NUMBER,
          QUESTION_TYPES.DECIMAL
        ].includes(this.thisQuestionType) &&
        this.isKPIthisQuestion &&
        this.isImportedData
      ) {
        ret = true;
      }
      return ret;
    },
    foundImportedAnswerItem() {
      return this.question?.foundImportedAnswerItem;
    },
    importedObjective() {
      return this.foundImportedAnswerItem
        ? this.foundImportedAnswerItem.importedObjective
        : null;
    },
    foundObjectiveValue() {
      let found = this.getImportedAndKPIObjectiveValue(this.question);
      return found;
    },
    calcBackgroundColor() {
      if (this.isKPIthisQuestion && this.foundObjectiveValue !== null) {
        let objectivo = !isNaN(this.foundObjectiveValue)
          ? parseFloat(this.foundObjectiveValue)
          : null;
        let value = parseFloat(this.initialValue);
        if (
          objectivo !== null &&
          !isNaN(objectivo) &&
          value !== null &&
          !isNaN(value)
        ) {
          if (value >= objectivo) return "back_green";
          else return "back_red";
        }
      } else {
        if (this.optionScores && !this.optionScores?.PSValues) {
          return "";
        } else if (
          this.optionScores?.PSValues &&
          !isNaN(parseFloat(this.maximumScore))
        ) {
          if (this.initialValue === null) return "";
          if (
            Array.isArray(this.initialValue) &&
            this.initialValue.length === 0
          ) {
            return "";
          }
          if (this.isCurrentScoreBigThanMaximum) {
            return "back_green";
          } else if (this.maximumScore > 0) {
            return "back_red";
          }
        }
      }
      return "";
    },
    hasImage() {
      if (this.question.surveyQuestionPhoto) return true;
      return false;
    }
  },

  data: function() {
    return {
      result: null,
      type: null,
      typeStr: null
    };
  },
  methods: {
    onClickSaveComments(saveData) {
      this.$emit("onClickSaveComments", saveData);
    },

    deleteAttachment(delId) {
      this.$emit("deleteAttachmentItem", delId, this.typeStr);
    },

    updatePhotosPortalAttributes(update_data) {
      this.$emit("updatePhotosPortalAttributes", update_data, this.typeStr);
    },

    onClickCompQueImage(image) {
      this.$emit("onClickCompQueImage", image);
    },

    onClickCorrectionAlert(worksession_survey_response_approval) {
      this.$emit(
        "onClickCorrectionAlert",
        worksession_survey_response_approval
      );
    },

    emit() {
      this.$emit("input", this.strValue, this.typeStr);
    },

    getThumbnailUri(image) {
      return ApiService.getThumbnailUri(image);
    },

    formatStrPSPointsLine() {
      const { currentScore, maximumScore } = this;
      let currentScorePart = null;
      let maximumScorePart = null;
      if (!isNaN(maximumScore) && maximumScore > 0) {
        maximumScorePart = `/ ${maximumScore}pts`;
      }
      if ((!isNaN(currentScore) && currentScore > 0) || maximumScorePart) {
        currentScorePart = `${currentScore}`;
      }
      let retStr = "";
      if (currentScorePart) {
        retStr += currentScorePart;
      }
      if (maximumScorePart) {
        retStr += maximumScorePart;
      }
      return `${retStr}`;
    }
  },

  async mounted() {
    this.type = this.question.type;
    this.typeStr = this.question.survey_question_type.type;
    this.result = this.initialValue;
  }
};
</script>

<style>
input.survey-input-xs,
.survey-input,
.survey-textarea {
  border: 1px solid #222;
  padding: 5px;
}
.survey-textarea {
  width: 100%;
}
.question-label {
  text-align: center;
  background: #faebd7;
  padding: 0 10px;
  font-size: 15px;
  color: #000;
  border: 1px solid #888;
}
.question-content {
  border: 1px solid #888;
  border-top: none;
  padding: 8px;
}
.questionbox {
  max-width: 100%;
  min-width: 150px;
}
.question-content .v-input--selection-controls {
  margin-top: 0px;
}
.question-content .v-radio {
  margin: 0 !important;
}
.question-content .v-input--selection-controls .v-input__slot,
.question-content .v-input--selection-controls .v-radio {
  margin: 0 !important;
}
.survey-input-number-xs.isImportedData {
  background: #cfcfcf;
}
.question-content .isImportedData.input_checkbox,
.question-content .isImportedData .v-input--radio-group__input,
.question-content .isImportedData .v-input__slot,
.question-content .isImportedData .v-text-field__slot,
.question-content .surveyquestion-datepicker.isImportedData {
  background: #cfcfcf !important;
}
</style>
